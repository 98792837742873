import { useGetPartnerCoursesQuery } from '@/Queries';
import { Category, Partner } from '@/Types';

import { CoursesTable } from './Components/Table/CoursesTable';
import Container from './Courses.styles';

type InactiveCoursesProps = {
  loading: boolean;
  casPublicId: Partner['casPublicId'];
  categories: Category[];
};

const InactiveCourses = ({ casPublicId, loading, categories }: InactiveCoursesProps) => {
  const { data: courses = [], isLoading: isLoadingCourses } = useGetPartnerCoursesQuery({
    casPublicId,
    isActiveCourse: false,
  });

  return (
    <Container>
      <CoursesTable
        courses={courses}
        categories={categories}
        showCourseSearch
        isPastCourse
        loading={loading || isLoadingCourses}
      />
    </Container>
  );
};

export default InactiveCourses;
