import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { getCoursesPathname } from '@/Mappers';
import { useGetPartnerCoursesQuery } from '@/Queries';
import InfoCourseIcon from '@/Static/Icons/course_delay_icon.svg';
import { Category, Partner } from '@/Types';

import { CoursesTable } from './Components/Table/CoursesTable';
import Container from './Courses.styles';

type ActiveCoursesProps = {
  loading: boolean;
  casPublicId: Partner['casPublicId'];
  categories: Category[];
};

const ActiveCourses = ({ casPublicId, categories, loading }: ActiveCoursesProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const currentLanguage = useCurrentLanguage();

  const { data: courses = [], isLoading: isLoadingCourses } = useGetPartnerCoursesQuery({
    casPublicId,
    isActiveCourse: true,
  });

  const handleToastMessage = useCallback((value: boolean) => {
    setOpenToastMessage(value);
  }, []);

  useEffect(() => {
    if (queryParams.success === 'show') {
      setOpenToastMessage(true);

      navigate(getCoursesPathname(currentLanguage));
    }
  }, [currentLanguage, navigate, queryParams.success]);

  return (
    <Container>
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={() => handleToastMessage(false)}
        customMessage={intl.formatMessage({
          id: 'toast.save.course',
          defaultMessage:
            'Deine Änderungen werden innerhalb der nächsten 10 min. in der App und im Web sichtbar.',
        })}
        customIcon={InfoCourseIcon}
      />
      <CoursesTable
        courses={courses}
        categories={categories}
        showAddNew
        showCourseSearch
        loading={loading || isLoadingCourses}
      />
    </Container>
  );
};

export default ActiveCourses;
